// Generated by Framer (36a78eb)

import { addFonts, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Arrow from "https://framerusercontent.com/modules/amUBM2bzmV1AZgGGeOOU/Bz9UQAKgfMQMwUGmnZ4r/k7Tpf2jiV.js";
import BuyTemplateButton from "https://framerusercontent.com/modules/Apk06RriukW7D2eEoGlJ/mP5kkuCXmaQLW34qovNJ/DqGFJQyu4.js";
import BuyTemplateButton1 from "https://framerusercontent.com/modules/zn9CQVjfhmouy8QKn0Ue/VN9hgVmjdk90LqwJWOCj/L0DerOIDo.js";
const ArrowFonts = getFonts(Arrow);
const BuyTemplateButtonFonts = getFonts(BuyTemplateButton);
const BuyTemplateButton1Fonts = getFonts(BuyTemplateButton1);

const enabledGestures = {t5B2x5VvA: {hover: true}};

const cycleOrder = ["t5B2x5VvA"];

const serializationHash = "framer-2L8N8"

const variantClassNames = {t5B2x5VvA: "framer-v-1ba70g4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 615, type: "spring"}};

const transformTemplate = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "t5B2x5VvA", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeargnqhaj = activeVariantCallback(async (...args) => {
setVariant(CycleVariantState)
})

useOnVariantChange(baseVariant, {default: onAppeargnqhaj})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2L8N8", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1ba70g4", className)} data-framer-name={"Remove This Buy Promo"} data-highlight layoutDependency={layoutDependency} layoutId={"t5B2x5VvA"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"t5B2x5VvA-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-bex1q2-container"} data-framer-name={"Remove This Arrow"} layoutDependency={layoutDependency} layoutId={"q081zfrUj-container"} name={"Remove This Arrow"} transformTemplate={transformTemplate}><Arrow height={"100%"} id={"q081zfrUj"} layoutId={"q081zfrUj"} name={"Remove This Arrow"} variant={"XHSjoYiUv"} width={"100%"}/></motion.div><motion.div className={"framer-4hfj9q-container"} data-framer-name={"Remove This Buy Button"} layoutDependency={layoutDependency} layoutId={"Mo9III3WJ-container"} name={"Remove This Buy Button"} transformTemplate={transformTemplate}><BuyTemplateButton height={"100%"} id={"Mo9III3WJ"} layoutId={"Mo9III3WJ"} link={"https://finestdevs.com/buy-sharpy"} name={"Remove This Buy Button"} title={"Get This Template for Free"} variant={"Av49agalG"} width={"100%"}/></motion.div><motion.div className={"framer-cwqh8a-container"} data-framer-name={"Remove This Buy Button"} layoutDependency={layoutDependency} layoutId={"Q9zIVk9g7-container"} name={"Remove This Buy Button"} transformTemplate={transformTemplate}><BuyTemplateButton1 height={"100%"} id={"Q9zIVk9g7"} layoutId={"Q9zIVk9g7"} link={"https://framerbite.com/all-access"} name={"Remove This Buy Button"} title={"Get All Templates for $99"} variant={"k0DK54DBn"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2L8N8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2L8N8 .framer-1l0p891 { display: block; }", ".framer-2L8N8 .framer-1ba70g4 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 157px; min-width: 176px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-2L8N8 .framer-bex1q2-container { bottom: 105px; flex: none; height: auto; left: 89%; position: absolute; width: auto; }", ".framer-2L8N8 .framer-4hfj9q-container { bottom: 52px; flex: none; height: auto; left: 49%; position: absolute; width: auto; z-index: 1; }", ".framer-2L8N8 .framer-cwqh8a-container { bottom: 0px; flex: none; height: auto; left: 52%; position: absolute; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2L8N8 .framer-1ba70g4 { gap: 0px; } .framer-2L8N8 .framer-1ba70g4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-2L8N8 .framer-1ba70g4 > :first-child { margin-left: 0px; } .framer-2L8N8 .framer-1ba70g4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 157
 * @framerIntrinsicWidth 176
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"xkuAlaoP5":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerTnWrKgtPa: React.ComponentType<Props> = withCSS(Component, css, "framer-2L8N8") as typeof Component;
export default FramerTnWrKgtPa;

FramerTnWrKgtPa.displayName = "Button Area";

FramerTnWrKgtPa.defaultProps = {height: 157, width: 176};

addFonts(FramerTnWrKgtPa, [...ArrowFonts, ...BuyTemplateButtonFonts, ...BuyTemplateButton1Fonts])